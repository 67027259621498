
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import depositBanner from '~/mixins/common/deposit-banner'
export default {
    name: 'DepositBanner',
    mixins: [cms, depositBanner],
    computed: {
        ...mapGetters({
            bannerDeposit: 'cms/bannerDeposit',
        }),
        displayBannerDeposit() {
            if (this.bannerDeposit._id) {
                return this.bannerDeposit.is_visible
            }
            return false
        },
        currentImagePath() {
            return this.getLocaleLang(
                this.isMobileOnly()
                    ? this.bannerDeposit.thumbnail_path
                    : this.bannerDeposit.image_path
            )
        },
    },
    mounted() {
        // Call this store action only when player/deposits
        if (
            this.$route.path === '/player/deposits' &&
            !this.bannerDeposit?.component_code
        )
            this.$store.dispatch('cms/cmsComponentCode', {
                platform: this.isMobileOnly() ? 2 : 1,
                component_code: 'BannerDeposit',
            })
    },
}
