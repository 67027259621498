
import Login from '~/mixins/pages/login/login'

export default {
    name: 'Theme3DesktopLoginForm',
    mixins: [Login],
    data() {
        return {}
    },
}
